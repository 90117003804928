<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">Exinda</h3>
		</div>
		<div class="card-body px-5 pt-0">
			<ITCSpinner :loaded="loaded" v-if="!timeout">
				<div class="row mb-4">
					<div class="col-md-6 pr-2">
						<div class="graph-container">
							<h4 class="side-title">Downstream Applications</h4>
							<apexchart type="donut" :height="height" :options="downapps" :series="downapps.series" v-if="downapps.series.length > 0" />
							<div v-else class="d-flex justify-content-center align-items-center" :style="{ height: height + 30 + 'px' }">
								<h4>No Data Available</h4>
							</div>
						</div>
					</div>
					<div class="col-md-6 pl-2">
						<!-- <h4>Upstream Applications</h4> -->
						<div class="graph-container">
							<h4 class="side-title">Upstream Applications</h4>
							<apexchart type="donut" :height="height" :options="upapps" :series="upapps.series" v-if="upapps.series.length > 0" />
							<div v-else class="d-flex justify-content-center align-items-center" :style="{ height: height + 30 + 'px' }">
								<h4>No Data Available</h4>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 pr-2">
						<!-- <h4>Downstream IP</pl-2h4> -->
						<div class="graph-container">
							<h4 class="side-title">Downstream IP</h4>
							<apexchart type="donut" :height="height" :options="downip" :series="downip.series" v-if="downip.series.length > 0" />
							<div v-else class="d-flex justify-content-center align-items-center" :style="{ height: height + 30 + 'px' }">
								<h4>No Data Available</h4>
							</div>
						</div>
					</div>
					<div class="col-md-6 pl-2">
						<!-- <h4>Upstream IP</h4> -->
						<div class="graph-container">
							<h4 class="side-title">Upstream IP</h4>
							<apexchart type="donut" :height="height" :options="upip" :series="upip.series" v-if="upip.series.length > 0" />
							<div v-else class="d-flex justify-content-center align-items-center" :style="{ height: height + 30 + 'px' }">
								<h4>No Data Available</h4>
							</div>
						</div>
					</div>
				</div>
				<div class="pull-right"><span>* Data since 00:00 UTC today</span></div>
			</ITCSpinner>
			<div v-else>
				<b-alert show variant="warning"> <i class="flaticon-warning text-white mr-2" />Response from Exinda timed out </b-alert>
			</div>
		</div>
	</div>
</template>

<script>
import { KMG } from '@/helpers';

export default {
	name: 'Exinda',
	props: ['id', 'properties'],
	data() {
		return {
			loaded: false,
			timeout: false,
			data: {},
			height: 300,
			downapps: { series: [] },
			upapps: { series: [] },
			downip: { series: [] },
			upip: { series: [] },
		};
	},
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	methods: {
		processGraph(dirtype) {
			var aseries = [];
			var appnames = [];

			appnames = Object.keys(this.data.data[dirtype]);
			appnames.forEach(app => {
				aseries.push(this.data.data[dirtype][app]);
			});

			return {
				series: aseries,
				labels: appnames,
				theme: {
					palette: 'palette2',
				},
				chart: {
					dropShadow: {
						enabled: true,
						color: '#111',
						top: -1,
						left: 3,
						blur: 3,
						opacity: 0.2,
					},
				},
				plotOptions: {
					pie: {
						offsetY: 25,
						customScale: 0.9,
						startAngle: -90,
						endAngle: 270,
					},
					labels: {
						show: true,
						total: {
							show: true,
							label: 'Applications',
						},
					},
				},
				tooltip: {
					y: {
						formatter: function(val, opts) {
							return KMG(val);
						},
					},
				},
				dataLabels: {
					enabled: false,
				},
				// fill: {
				// 	type: 'gradient',
				// },
				legend: {
					formatter: function(val, opts) {
						return val + ' - ' + KMG(opts.w.globals.series[opts.seriesIndex]);
					},
					width: 300,
					position: 'right',
					horizontalAlign: 'right',
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: 'bottom',
							},
						},
					},
				],
			};
		},
		processData() {
			this.downapps = this.processGraph('out_app');
			this.upapps = this.processGraph('in_app');
			this.downip = this.processGraph('out_ip');
			this.upip = this.processGraph('in_ip');
		},
	},
	created() {
		this.loaded = false;
		this.$http
			.post(`exinda/${this.id}`, { exinda: this.properties.exinda })
			.then(resp => {
				this.data = resp.data;

				this.processData();
				this.loaded = true;
			})
			.catch(e => {
				if (/Error:\stimeout\sof\s\d0000ms\sexceeded/.test(e)) {
					this.loaded = true;
					this.timeout = true;
				}
				console.log('error', e);
			});
	},
};
</script>

<style scoped>
::v-deep .graph-container .apexcharts-legend {
	top: 5px !important;
}
.graph-container {
	border: 1px solid rgb(225, 232, 255);
	border-radius: 10px;
	position: relative;
}
.side-title {
	position: absolute;
	left: 5px;
	font-size: 1.5em;
	color: rgba(63, 66, 85, 0.5);
	/* transform: rotate(180deg); */
	/* writing-mode: vertical-rl; */
}
</style>
